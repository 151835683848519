import { motion } from "framer-motion";
import React from "react";
import { FaQuoteLeft } from "react-icons/fa6";

interface ReviewProps {
  text: string;
  title: string;
  name: string;
  image: string;
  color: string;
  logo: string;
}

const Review: React.FC<ReviewProps> = ({
  text,
  title,
  name,
  image,
  color,
  logo,
}) => {
  return (
    <motion.div
      style={{ x: "40vw" }} // Start at the center (0)
      animate={{ x: 0 }} // Animate to the center (0)
      transition={{ type: "tween", duration: 0.5 }}
      className="rounded-md max-w-[100%] pr-4 md:pr-8 bg-gray-50/60 backdrop-blur-md ring-1 ring-gray-200/50 shadow-lg md:shadow hover:shadow-lg max-h-[90vh] md:max-h-[375px] md:w-[calc(100vw-5.75rem)] md:max-w-2xl flex snap-start snap-always shrink-0 first-of-type:scroll-m-10 scroll-m-5 overflow-hidden relative">
      {/* Left Side: Photo with tilted background */}
      <div
        className="relative w-full md:w-1/2 min-w-[140px] md:min-w-[180px] h-full flex justify-center items-center"
        style={{ backgroundColor: "transparent" }}>
        <svg
          className="absolute inset-0 w-full h-full"
          viewBox="0 0 100 100"
          preserveAspectRatio="none">
          <polygon points="0,0 100,0 70,100 0,100" fill={color} />
        </svg>
        <img
          className="w-20 h-20 md:w-32 md:h-32 rounded-full object-fill absolute left-[3.5rem] md:left-[5rem] top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
          src={image}
          alt={name}
          width="96"
          height="96"
          loading="lazy"
        />
      </div>

      {/* Right Side: Text, Name, Title, and Logo */}
      <div className="flex flex-col justify-between p-2 md:p-4 flex-grow">
        <blockquote className="flex-1 md:flex md:items-center text-lg pt-4 overflow-auto max-h-[75%] md:max-h-[65%] relative text-tollguru-text">
          <span className="relative">
            <span className="relative z-10">{text}</span>
            <span className="text-8xl absolute -top-5 left-10 transform -translate-x-8 -translate-y-4">
              <FaQuoteLeft color="#D4D4D480" />
            </span>
          </span>
        </blockquote>
        <div className="flex items-center gap-2 mt-2">
          <div className="flex-grow">
            <h2 className="font-medium text-sm md:text-lg text-tollguru-heading">
              {name}
            </h2>
            <p className="text-xs md:text-sm text-tollguru-text">{title}</p>
          </div>
          <img
            src={logo}
            alt="Company Logo"
            className="w-[20%] object-contain aspect-[3/2]"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Review;
