import React, { useState, useEffect } from "react";
import Review from "./Review";
import { motion, AnimatePresence } from "framer-motion";

interface Testimonial {
  name: string;
  title: string;
  image: string;
  color: string;
  logo: string;
  text: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Josh McCord",
    title: "Co-founder & CDO",
    image: "https://cdn.tollguru.com/tollguru_v2/video/customers/frayt-2.png",
    color: "#FF9902",
    logo: "https://cdn.tollguru.com/tollguru_v2/video/customers/brand/10-Fryat-logo.png",
    text: "Without any toll data in our system, we would charge just a flat markup, 20 or 30% more. That was not very sustainable. TollGuru integration made a huge difference to our operations. We were able to give our customers an exact price on deliveries and this led to much less negotiation after the fact.",
  },
  {
    name: "Dylan Lorimer",
    title: "Sr. Director, Product Mgmt",
    image: "https://cdn.tollguru.com/tollguru_v2/video/customers/lyft.png",
    color: "#EA0B8C",
    logo: "https://cdn.tollguru.com/tollguru_v2/video/customers/brand/lyft-2.png",
    text: "We use MapUp to accurately calculate tolls for reimbursement to our drivers. We look forward to MapUp to build a product that goes the next step and helps pay for tolls with each tolling agency.",
  },
  {
    name: "Jason Kirton",
    title: "VP of Engineering",
    image: "https://cdn.tollguru.com/tollguru_v2/video/customers/dispatch.png",
    color: "#003A5D",
    logo: "https://cdn.tollguru.com/tollguru_v2/video/customers/brand/07-Dispatch-logo.png",
    text: "We've been super happy with TollGuru service. We made lots of calls to TollGuru API without any problems. we're going to be expanding into 100 markets. So from that perspective, if any of those markets have tolls, we will be using TollGuru.",
  },
  {
    name: "Viktor Rudoy",
    title: "Head of Product, Maps & Geo",
    image: "https://cdn.tollguru.com/tollguru_v2/video/customers/indrive.png",
    color: "#A7E92E",
    logo: "https://cdn.tollguru.com/tollguru_v2/video/customers/brand/02-InDrive-logo.png",
    text: "Our goal was to improve fare negotiations. By incorporating accurate toll costs into our fare negotiations, we’re able to create a more transparent and fair experience for both our riders and drivers, and improve overall efficiency of our service",
  },
];

const Testimonials: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideIn, setSlideIn] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1025);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const changeSlide = () => {
      setSlideIn(false);
      setTimeout(() => {
        setCurrentSlide(
          (prev) => (prev + 1) % Math.ceil(testimonials.length / 1)
        );
        setSlideIn(true);
      }, 500);
    };

    const timer = setTimeout(changeSlide, 10000);
    return () => clearTimeout(timer);
  }, [currentSlide, slideIn, isSmallScreen]);

  const numOfVisibleReviews = 1;
  const totalSlides = Math.ceil(testimonials.length / numOfVisibleReviews);

  const goToSlide = (index: number) => {
    if (index !== currentSlide) {
      setSlideIn(false);
      setTimeout(() => {
        setCurrentSlide(index);
        setSlideIn(false);
      }, 500);
    }
  };

  return (
    <div className="mt-24 relative overflow-hidden px-4 sm:px-6 lg:px-8">
      <h2 className="text-tollguru-heading text-3xl lg:text-4xl font-bold lg:tracking-tight text-left md:text-center">
        Driving Real Impact for Our Customers
      </h2>
      <p
        className="text-lg text-tollguru-text text-left md:text-center mx-auto mt-4"
        style={{ maxWidth: "800px" }}>
        From lowering toll spend to increasing driver and customer satisfaction,
        discover how the world's largest fleets improve their profit margins and
        better serve their communities
      </p>
      <motion.div
        className={`flex gap-4 justify-center mt-10 transition-transform ease-linear duration-500`}
        style={{ height: "375px" }}>
        {testimonials
          .slice(
            currentSlide * numOfVisibleReviews,
            currentSlide * numOfVisibleReviews + numOfVisibleReviews
          )
          .map((item) => (
            <Review key={item.name} {...item} />
          ))}
      </motion.div>

      <div className="flex justify-center gap-2 mt-4 mb-8">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <button
            key={index}
            className={`w-4 h-4 rounded-full shadow-2xl ${
              index === currentSlide
                ? "bg-text-gradient"
                : "bg-white border border-gray-300"
            }`}
            onClick={() => goToSlide(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
